import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './Dashboard.css'
const processData = (data) => {
  let levels = Array.from({ length: 16 }, (_, i) => ({
    level: i,
    size: 0,
    up: 0,
    down: 0,
    upPrices: {},
    downPrices: {},
    predictionSize: 0,
  }));
  // let predictionSize = 0;

  data.forEach(row => {
    const level = row.status;
    const predictions = JSON.parse(row.predictions);

    Object.keys(predictions).forEach(date => {
      // predictionSize = 0;
      Object.keys(predictions[date]).forEach(time => {
        const predictionData = predictions[date][time];
        const prediction = predictionData.prediction;
        const insights = predictionData.insights;

        levels[level].predictionSize++;
        // predictionSize++;

        if (prediction === 1) {
          levels[level].up++;
          if (insights) {
            levels[level].upPrices[insights] = (levels[level].upPrices[insights] || 0) + 1;
          }
        } else if (prediction === 2) {
          levels[level].down++;
          if (insights) {
            levels[level].downPrices[insights] = (levels[level].downPrices[insights] || 0) + 1;
          }
        }
      });
    });
    levels[level].size++;
  });

  levels = levels.map(level => ({
    ...level,
    upPercentage: level.predictionSize > 0 ? ((level.up / level.predictionSize) * 100).toFixed(2) : '0.00',
    downPercentage: level.predictionSize > 0 ? ((level.down / level.predictionSize) * 100).toFixed(2) : '0.00',
    frequentUpPrice: Object.entries(level.upPrices).sort((a, b) => b[1] - a[1])[0]?.[0] || '',
    frequentDownPrice: Object.entries(level.downPrices).sort((a, b) => b[1] - a[1])[0]?.[0] || '',
  }));

  return levels;
};




export default function Dashboard({apilink}) {
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [levels, setLevels] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('');
  const [token, setToken] = useState('');
  const [performanceData, setPerformanceData] = useState([
    { name: 'Top 10', size: 0, up: 0, down: 0, upPercentage: '0.00', downPercentage: '0.00', upPrice: '0.00', downPrice: '0.00' },
    { name: 'Top 100', size: 0, up: 0, down: 0, upPercentage: '0.00', downPercentage: '0.00', upPrice: '0.00', downPrice: '0.00' },
    { name: '2 week streak', size: 0, up: 0, down: 0, upPercentage: '0.00', downPercentage: '0.00', upPrice: '0.00', downPrice: '0.00' },
    { name: '3 week streak', size: 0, up: 0, down: 0, upPercentage: '0.00', downPercentage: '0.00', upPrice: '0.00', downPrice: '0.00' },
    { name: 'monthly streak', size: 0, up: 0, down: 0, upPercentage: '0.00', downPercentage: '0.00', upPrice: '0.00', downPrice: '0.00' }
  ]);

  useEffect(() => {
    const login = async () => {
        try {
            const response = await axios.post(`${apilink}/auth`, { username: 'Shyam619', password: 'Test@1234' });
            setToken(response.data.token);
        } catch (error) {
            console.error('Login Error:', error);
        }
    };
    // const login = () => {
    //   fetch(apilink+'/auth', { username: 'Shyam619', password: 'Test@1234' })
    //     .then(response => {
    //       setToken(response);
    //     });
    // };

    login();
}, [apilink]);

  useEffect(() => {
    // Fetch the data from your API or database
      if (!token) return;
      fetch(apilink+'/all', {
        headers: { Authorization: token }
      })
        .then(response => response.json())
        .then(data => {
          setData(data);
          setLevels(processData(data));
          const filteredData = filterData(data);
          setPerformanceData(processData2(filteredData));
          // setPerformanceData(processData2(data));
        });

      // eslint-disable-next-line
  }, [apilink,token]);

  const filterData = (data) => {
    return data.filter(row => {
      const predictions = JSON.parse(row.predictions);
      let matchesDate = true;
      let matchesTime = true;
  
      if (dateFilter) {
        const formattedDate = formatDateForComparison(dateFilter);
        matchesDate = Object.keys(predictions).some(date => date === formattedDate);
      }
  
      if (timeFilter && dateFilter) {
        const formattedDate = formatDateForComparison(dateFilter);
        matchesTime = Object.keys(predictions[formattedDate] || {}).some(time => time === timeFilter);
      }
  
      return matchesDate && (timeFilter ? matchesTime : true);
    });
  };
  

  const processData2 = (data) => {
    const processedData = data.map((user) => {
      const predictions = JSON.parse(user.predictions);
      let trueResultsCount = 0;
      let upCount = 0;
      let downCount = 0;
      let upInsights = {};
      let downInsights = {};
      let dailyStreaks = {};

      for (const [date, times] of Object.entries(predictions)) {
        let dailyTrueResults = 0;
// eslint-disable-next-line
        for (const [time, details] of Object.entries(times)) {
          if (details.result) trueResultsCount++;
          if (details.result) dailyTrueResults++;
          if (details.prediction === 1) {
            upCount++;
            if (details.insights) {
              upInsights[details.insights] = (upInsights[details.insights] || 0) + 1;
            }
          }
          if (details.prediction === 2) {
            downCount++;
            if (details.insights) {
              downInsights[details.insights] = (downInsights[details.insights] || 0) + 1;
            }
          }
        }

        if (dailyTrueResults >= 12) {
          dailyStreaks[date] = dailyTrueResults;
        }
      }

      return {
        email: user.email,
        trueResultsCount,
        upCount,
        downCount,
        upInsights,
        downInsights,
        dailyStreaks,
      };
    });

    const sortedData = processedData.sort((a, b) => b.trueResultsCount - a.trueResultsCount);

    const calculateStreaks = (streakLength) => processedData.reduce((acc, user) => {
      const streakCounts = Object.keys(user.dailyStreaks).length;
      if (streakCounts >= streakLength) acc++;
      return acc;
    }, 0);

    // const totalUsers = data.length;

    const calculateMostUsedInsight = (insights) => {
      return Object.entries(insights).reduce((a, b) => (b[1] > a[1] ? b : a), ["", 0]);
    };

    const createPerformanceEntry = (name, size) => {
      const relevantUsers = sortedData.slice(0, size);
      const upCount = relevantUsers.reduce((sum, user) => sum + user.upCount, 0);
      const downCount = relevantUsers.reduce((sum, user) => sum + user.downCount, 0);
      const upInsights = relevantUsers.reduce((insights, user) => {
        for (const [key, value] of Object.entries(user.upInsights)) {
          insights[key] = (insights[key] || 0) + value;
        }
        return insights;
      }, {});
      const downInsights = relevantUsers.reduce((insights, user) => {
        for (const [key, value] of Object.entries(user.downInsights)) {
          insights[key] = (insights[key] || 0) + value;
        }
        return insights;
      }, {});
// eslint-disable-next-line
      const [mostUsedUpInsight, upInsightCount] = calculateMostUsedInsight(upInsights);
      // eslint-disable-next-line
      const [mostUsedDownInsight, downInsightCount] = calculateMostUsedInsight(downInsights);

      return {
        name,
        size,
        up: upCount,
        down: downCount,
        upPercentage: ((upCount / (upCount + downCount)) * 100).toFixed(2),
        downPercentage: ((downCount / (upCount + downCount)) * 100).toFixed(2),
        upPrice: mostUsedUpInsight,
        downPrice: mostUsedDownInsight,
      };
    };

    return [
      createPerformanceEntry('Top 10', 10),
      createPerformanceEntry('Top 100', 100),
      createPerformanceEntry('2 week streak', calculateStreaks(14)),
      createPerformanceEntry('3 week streak', calculateStreaks(21)),
      createPerformanceEntry('monthly streak', calculateStreaks(28))
    ];
  };

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTimeFilter(event.target.value);
  };

  // Convert `dd/mm/yyyy` to `yyyy-mm-dd` for date comparisons
  const formatDateForComparison = (date) => {
    const [day, month, year] = date.split('-');
    // return `${year}-${month}-${day}`;
    return `${year}/${month}/${day}`;
  };

  useEffect(() => {
  const filteredData = data.filter(row => {
    const predictions = JSON.parse(row.predictions);
    let matchesDate = true;
    let matchesTime = true;

    if (dateFilter) {
      const formattedDate = formatDateForComparison(dateFilter);
      matchesDate = Object.keys(predictions).some(date => date === formattedDate);
    }

    if (timeFilter && dateFilter) {
      const formattedDate = formatDateForComparison(dateFilter);
      matchesTime = Object.keys(predictions[formattedDate] || {}).some(time => time === timeFilter);
    }

    return matchesDate && (timeFilter ? matchesTime : true);
  });

  // const filteredLevels = processData(filteredData);
  setLevels(processData(filteredData));
  setPerformanceData(processData2(filteredData));

}, [dateFilter, timeFilter, data]);
// upPercentage: level.predictionSize > 0 ? ((level.up / level.predictionSize) * 100).toFixed(2) : '0.00',

  const totalSize = levels.reduce((acc, level) => acc + level.size, 0);
  const totalUp = levels.reduce((acc, level) => acc + level.up, 0);
  const totalUpSize = levels.reduce((acc, level) => acc + level.predictionSize, 0);
  const totalDown = levels.reduce((acc, level) => acc + level.down, 0);
  const totalDownSize = levels.reduce((acc, level) => acc + level.predictionSize, 0);
  const totalfreqUp = levels.reduce((acc, level) => {
    acc.push(Object.entries(level.upPrices).sort((a, b) => b[1] - a[1])[0]?.[0] || '');
    return Object.entries(acc).reduce((a, b) => (b[1] > a[1] ? b : a), ["", 0]);
  }, []);  
  const totalfreqDown = levels.reduce((acc, level) => {
    acc.push(Object.entries(level.downPrices).sort((a, b) => b[1] - a[1])[0]?.[0] || '');
    return Object.entries(acc).reduce((a, b) => (b[1] > a[1] ? b : a), ["", 0]);
  }, []);  
  const totalUpPercentage = totalUpSize > 0 ? ((totalUp / totalUpSize) * 100).toFixed(2) : '0.00';
  const totalDownPercentage = totalDownSize > 0 ? ((totalDown / totalDownSize) * 100).toFixed(2) : '0.00';

  console.log(totalfreqUp)
  // Generate time options
  const timeOptions = [];
  for (let hour = 12; hour <= 19; hour++) {
    for (let min = 0; min < 60; min += 30) {
      const hour12 = hour % 12 || 12;
      const period = hour >= 12 ? 'pm' : 'am';
      const timeStr = `${String(hour12).padStart(2, '0')}:${String(min).padStart(2, '0')} ${period}`;
      timeOptions.push(timeStr);
    }
  }


    // Your performance calculation logic here
  // const performanceData = calculatePerformanceData(processData2(data));
  // console.log(data);

  const handleExport = () => {
    const data = [];
    
    // Add table headers
    data.push(["Level", "Size", "UP %", "DOWN %", "Frequent UP Price", "Frequent DOWN Price"]);
    
    // Add table rows
    levels.forEach(level => {
      const row = [];
      row.push(level.level);
      row.push(level.size);
      row.push(level.upPercentage);
      row.push(level.downPercentage);
      row.push(level.frequentUpPrice);
      row.push(level.frequentDownPrice);
      data.push(row);
    });

    // Add total row
    data.push(["Total", totalSize, totalUpPercentage, totalDownPercentage, totalfreqUp[1], totalfreqDown[1]]);
    
    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Levels");
    
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "levels.xlsx");
  };


  const handleExport2 = () => {
    const data = [];

    // Add table headers
    data.push(["Category", "Size", "UP %", "DOWN %", "Frequent UP Price", "Frequent DOWN Price"]);

    // Add table rows
    performanceData.forEach(category => {
      const row = [];
      row.push(category.name);
      row.push(category.size);
      row.push(category.upPercentage);
      row.push(category.downPercentage);
      row.push(category.upPrice);
      row.push(category.downPrice);
      data.push(row);
    });

    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Performance Data");

    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "performance_data.xlsx");
  };

  const handleBack = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="Dashboard">
      <button className='back-list-btn' onClick={handleBack}>Back</button>
      <h1>Prediction Table</h1>
      <div className="filters">
          <label htmlFor="date-filter">Date:</label>
          <input
            type="date"
            id="date-filter"
            value={dateFilter}
            onChange={handleDateChange}
          />
          <label htmlFor="time-filter">Time:</label>
          <select
            id="time-filter"
            value={timeFilter}
            onChange={handleTimeChange}
          >
            <option value="">All Times</option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
          <button className='exportbtn' onClick={handleExport}>Export Levels to Excel</button>
        </div>
      <table>
        <thead>
          <tr>
            <th>Level</th>
            <th>Size</th>
            <th>UP %</th>
            <th>DOWN %</th>
            <th>Frequent UP Price</th>
            <th>Frequent DOWN Price</th>
          </tr>
        </thead>
        <tbody>
          {levels.map(level => (
            <tr key={level.level}>
              <td>{level.level}</td>
              <td>{level.size}</td>
              <td>{level.upPercentage}</td>
              <td>{level.downPercentage}</td>
              <td>{level.frequentUpPrice}</td>
              <td>{level.frequentDownPrice}</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>{totalSize}</td>
            <td>{totalUpPercentage}</td>
            <td>{totalDownPercentage}</td>
            <td>{totalfreqUp[1]}</td>
            <td>{totalfreqDown[1]}</td>
          </tr>
        </tbody>
      </table>
      {/* Performance Table Starts */}
      <h1>Performance Table</h1>
      <button className='exportbtn' onClick={handleExport2}>Export Performance Data to Excel</button>
      <h2>-</h2>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Size</th>
              <th>UP %</th>
              <th>DOWN %</th>
            <th>Frequent UP Price</th>
            <th>Frequent DOWN Price</th>
            </tr>
          </thead>
          <tbody>
            {performanceData.map((category, index) => (
              <tr key={index}>
                <td>{category.name}</td>
                <td>{category.size}</td>
                <td>{category.upPercentage}</td>
                <td>{category.downPercentage}</td>
                <td>{category.upPrice}</td>
                <td>{category.downPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Performance Table Ends */}
    </div>
    </div>
  )
}
