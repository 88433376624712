import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KYC.css';

export default function KYC({apilink}) {
    const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [token, setToken] = useState('');


  useEffect(() => {
    const login = async () => {
        try {
            const response = await axios.post(`${apilink}/auth`, { username: 'Shyam619', password: 'Test@1234' });
            setToken(response.data.token);
        } catch (error) {
            console.error('Login Error:', error);
        }
    };
    login();
}, [apilink]);

  useEffect(() => {
    if (!token) return;
    fetch(`${apilink}/all`, {
      headers: { Authorization: token }
    })
      .then(response => response.json())
      .then(data => {
        setUsers(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [apilink,token]);

  const handleApprove = (ID) => {
    fetch(`${apilink}/updateadminkyc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ID, kyc: 'true' }),
    })
    .then(response => response.json())
    .then(data => {
      setUsers(users.map(user => 
        user.ID === ID ? { ...user, kyc: 'true' } : user
      ));
      setSelectedUser(null);
    })
    .catch(error => console.error('Error updating data:', error));
  };

  const handleReject = (ID) => {
    fetch(`${apilink}/updateadminkyc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ID, kyc: 'rejected' }),
    })
    .then(response => response.json())
    .then(data => {
      setUsers(users.map(user => 
        user.ID === ID ? { ...user, kyc: 'rejected' } : user
      ));
      setSelectedUser(null);
    })
    .catch(error => console.error('Error updating data:', error));
  };
  const handleBack = () => {
    window.location.reload();
  };
  return (
    <div>
      <div>
      <button className='back-list-btn' onClick={handleBack}>Back</button>

      <h1>Pending KYC Users</h1>
      
      {!selectedUser && (
        <ul>
          {users.filter(user => user.kyc === 'pending').map(user => (
            <li className="listcardkyc" key={user.ID}>
              {user.username} - {user.email}
              <button onClick={() => setSelectedUser(user)}>View KYC Data</button>
            </li>
          ))}
        </ul>
      )}
      
      {selectedUser && (
        <div>
          <button className='back-list-btn' onClick={() => setSelectedUser(null)}>Back</button>
          <h2>KYC Data for {selectedUser.username}</h2>
          <ul>
            {JSON.parse(selectedUser.kycdata).map((image, index) => (
              <li key={index}>
                <img src={`https://salary.trading/salarytrading/imgg/${image}`} alt={`KYC Document ${index}`} style={{width: '200px'}} />
              </li>
            ))}
          </ul>
          <button className='userbtn' onClick={() => handleApprove(selectedUser.ID)}>Approve</button>
          <button className='userbtn' onClick={() => handleReject(selectedUser.ID)}>Reject</button>
        </div>
      )}
    </div>
    </div>
  )
}
