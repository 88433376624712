import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import './Withdraw.css'

Modal.setAppElement('#root'); // Bind modal to your appElement (root div)

export default function Withdraw({apilink}) {
    const [withdrawals, setWithdrawals] = useState([]);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch(apilink+'/allwith')  // Adjust this URL to your actual API endpoint
      .then(response => response.json())
      .then(data => {
        setWithdrawals(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [apilink]);

  const handleStatusUpdate = (ID, status) => {
    console.log(ID)
    fetch(apilink+'/updateadminwith', {  // Adjust this URL to your actual API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID, status })
    })
    .then(response => response.json())
    .then(data => {
      // Update the local state or refetch the data
      setWithdrawals(prev => prev.filter(withdrawal => withdrawal.ID !== ID));
    })
    .catch(error => console.error('Error updating status:', error));
  };

  const openModal = (withdrawal) => {
    setSelectedWithdrawal(withdrawal);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedWithdrawal(null);
    setIsModalOpen(false);
  };

  const handleExport = () => {
    const data = [];

    // Add table headers
    data.push(["Fullname", "Country", "Mobile", "Userid", "City", "Email", "Amount", "Status", "Mode", "Details"]);

    // Add table rows
    withdrawals.forEach(withdrawal => {
      if (withdrawal.status === 'pending') {
        const row = [];
        row.push(withdrawal.fullname);
        row.push(withdrawal.country);
        row.push(withdrawal.mobile);
        row.push(withdrawal.userid);
        row.push(withdrawal.city);
        row.push(withdrawal.email);
        row.push(withdrawal.amount);
        row.push(withdrawal.status);
        row.push(withdrawal.mode);
        row.push(withdrawal.details);
        data.push(row);
      }
    });

    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Withdrawals");

    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "withdrawals.xlsx");
  };
  const handleBack = () => {
    window.location.reload();
  };

  return (
    <div>
      <div>
      <button className='back-list-btn' onClick={handleBack}>Back</button>
      <h1>Pending Withdrawals</h1>
      <button className='exportbtn' onClick={handleExport}>Export Withdrawals to Excel</button>
      <table>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Country</th>
            <th>Mobile</th>
            <th>Userid</th>
            <th>City</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {withdrawals.map(withdrawal => (
            withdrawal.status === 'pending'&&
            <tr key={withdrawal.ID} onClick={() => openModal(withdrawal)}>
              <td>{withdrawal.fullname}</td>
              <td>{withdrawal.country}</td>
              <td>{withdrawal.mobile}</td>
              <td>{withdrawal.userid}</td>
              <td>{withdrawal.city}</td>
              <td>{withdrawal.email}</td>
              <td>{withdrawal.amount}</td>
              <td>
                <button className='userbtnwith' onClick={(e) => { e.stopPropagation(); handleStatusUpdate(withdrawal.ID, 'success'); }}>Paid</button>
                <button className='userbtnwith' onClick={(e) => { e.stopPropagation(); handleStatusUpdate(withdrawal.ID, 'failed'); }}>Fail</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Withdrawal Details"
      >
        {selectedWithdrawal && (
          <div>
            <h2>Details for {selectedWithdrawal.fullname}</h2>
            <ul>
              {JSON.parse(selectedWithdrawal.details).map((detail, index) => (
                <li key={index}>
                  {detail} <button className='back-list-btn'style={{color:'black'}} onClick={() => navigator.clipboard.writeText(detail)}>Copy</button>
                </li>
              ))}
            </ul>
            <button className='userbtn' onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
    </div>
  )
}
