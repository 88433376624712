import React, {useEffect,useState} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// eslint-disable-next-line
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Reports.css'
import axios from 'axios'
import * as XLSX from 'xlsx';


export default function Reports({apilink,globaluuid}) {
  //Insights Starts here
  const screenWidth = window.innerWidth;
  const rotateDeg = screenWidth > 1000 && screenWidth < 1400?'42.5deg':'49deg';
  const rotatenDeg = screenWidth > 1000 && screenWidth < 1400?'-42.5deg':'-49deg';
  const [startDate, setStartDate] = useState(new Date());

  const UniDate = new Date();
  const UniHourset = UniDate.toLocaleTimeString([], { hour: '2-digit' , hour12: true}).replace(/ am| pm| AM| PM/i, '');
  const UniHours = UniHourset === '00'?'12':UniHourset;
  const UniMin = (UniDate.getMinutes() >= 30?'30':'00');
  const SetUni = (UniHours.length === 1?'0'+UniHours : UniHours) + ':' + (UniMin.length === 1?'0'+UniMin : UniMin);

  function handleDateSelect(e){
    setStartDate(e);
  }

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  function switchnow(e){
    document.getElementById('reports1').style.backgroundColor = "#1e2228";
    document.getElementById('reports2').style.backgroundColor = "#1e2228";
    document.getElementById('reports1').style.color = "#ffffff";
    document.getElementById('reports2').style.color = "#ffffff";
    document.getElementById('reports1').style.paddingTop = "8px";
    document.getElementById('reports2').style.paddingTop = "8px";
    document.getElementById('line-reports1').style.display = "none";
    document.getElementById('line-reports2').style.display = "none";
    document.getElementById('line-'+e.target.id).style.display = "block";
    e.target.style.backgroundColor = 'transparent'
    e.target.style.color = '#0094fd'
    e.target.style.paddingTop = '30px'
    setSwitch(e.target.id === 'reports1')    
  }
  const supertime = ['12:00','12:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00']
  const [superKeys,setsuperKeys] = useState('');
  const [superValues,setsuperValues] = useState('');
  const [openPrice,setOpenPrice] = useState('');
  const [Closeprice,setClosePrice] = useState('');
  const [Updown,setUpdown] = useState('');
  const [PredictionData, setPredictionData] = useState({});
  const [Switch, setSwitch] = useState(true);
  const [tradeData, setTradeData] = useState('');
  const currentDate = formatDate(new Date(startDate));


  useEffect(() => {

    if(!tradeData){
    axios.post(apilink+'/level', {globaluuid})
              .then(res => {
                console.log('decodedTime');
                const traderaw = res.data.tradetime;
                const decodetradeData = JSON.parse(traderaw);
                const decodedTime = new Date(decodetradeData['Last']);
                // decodedTime.setHours(decodedTime.getHours(),(decodedTime.getMinutes())+1,0,0);
                const decodeHourset = decodedTime.toLocaleTimeString([], { hour: '2-digit' , hour12: true}).replace(/ am| pm| AM| PM/i, '');
                const decodeHours = decodeHourset === '00'?'12':decodeHourset;
                const decodeMin = (decodedTime.getMinutes() === 30?'30':'00');
                const Temptime = (decodeHours.length === 1?'0'+decodeHours : decodeHours) + ':' + (decodeMin.length === 1?'0'+decodeMin : decodeMin);
                setTradeData(Temptime);
                console.log(decodedTime,Temptime);

              })
                .catch(err => console.log(err));  
              }


              if(superValues.length === 0){
    axios.post(apilink+'/timeline', {globaluuid})
            .then(res => {

              if ( !!res.data && !!res.data[currentDate] && Object.keys(res.data[currentDate]).length > 0) {
              setPredictionData(res.data[currentDate]);
              setsuperKeys(Object.keys(PredictionData).map(entry => entry));
              setsuperValues(Object.values(PredictionData).map(entry => entry.result));
              setOpenPrice(Object.values(PredictionData).map(entry => entry.previous));
              setClosePrice(Object.values(PredictionData).map(entry => entry.price));
              setUpdown(Object.values(PredictionData).map(entry => entry.prediction));


              
              } else {
                setPredictionData({});
                setsuperKeys([]);
                setsuperValues([]);
              } 
            })
              .catch(err => console.log(err));
            }


              // axios.post(apilink+'/level', {globaluuid})
              // .then(res => {
                
              // })
              //   .catch(err => console.log(err));          

  }, [globaluuid,apilink,currentDate,superKeys,superValues,PredictionData,tradeData,superValues.length]);
  //Insights ends here

  //Earnings starts here

  const [earnings, setEarnings] = useState([]);
  const [filteredEarnings, setFilteredEarnings] = useState([]);
  const [filter, setFilter] = useState('none');
  // const [granularity, setGranularity] = useState('day');
  const [customRange, setCustomRange] = useState({ start: '', end: '' });
  const [expanded, setExpanded] = useState(null);
  const EraningList = [0,1,2,2,2,5,5,5,5,5,10,15,20,30,40,50];
  const [myLevel,setMylevel] = React.useState('');


  useEffect(() => {
    axios.post(`${apilink}/level`, { globaluuid })
      .then(res => {
        const globalData = JSON.parse(res.data.predictions);
        console.log(myLevel)
        setMylevel(res.data.status);
        const parseData = (globalData) => {
          const sortedDates = Object.keys(globalData).sort((a, b) => new Date(b.split("/").reverse().join("-")) - new Date(a.split("/").reverse().join("-")));
          return sortedDates.map(date => ({
            date,
            results: Object.values(globalData[date]).map(entry => entry.result)
          }));
        };


        // Collect earnings for each day
        const calculateEarnings = (parsedData) => {
          const dailyEarnings = parsedData.map(day => {
            const correctPredictions = day.results.filter(result => result === true).length;
            const earnings = correctPredictions >= 12 ? EraningList[myLevel] : 0;
            return {
              date: new Date(day.date.split("/").reverse().join("-")),
              earnings
            };
          });
  
          const weeklyEarnings = [];
          for (let i = 0; i < dailyEarnings.length; i += 7) {
            const week = dailyEarnings.slice(i, i + 7);
            const correctDays = week.filter(day => day.earnings === 1).length;
            const earnings = correctDays < 3 ? -EraningList[myLevel] : week.reduce((sum, day) => sum + day.earnings, 0);
            weeklyEarnings.push(...week.map(day => ({ ...day, weeklyEarnings: earnings })));
          }
  
          return weeklyEarnings;
        };
  
        const parsedData = parseData(globalData);
        const weeklyEarnings = calculateEarnings(parsedData);
        setEarnings(weeklyEarnings);
        applyFilter(weeklyEarnings, filter);
      })
      .catch(err => console.log(err));
      // eslint-disable-next-line
  }, [apilink, globaluuid, filter]);

  const applyFilter = (earnings, filter) => {
    const now = new Date();
    let filtered;
  
    // Remove duplicates before filtering
    // eslint-disable-next-line
    const uniqueEarnings = earnings.filter(
      (earning, index, self) =>
        index === self.findIndex((e) => e.date.toDateString() === earning.date.toDateString())
    );

    switch (filter) {
      case 'previousDay':
        const previousDay = new Date(now);
        previousDay.setDate(now.getDate() - 1);
        filtered = uniqueEarnings.filter(e => isSameDay(e.date, previousDay));
        break;
      case 'lastWeek':
        filtered = getLastWeeks(uniqueEarnings, 1);
        break;
      case 'last30Days':
        filtered = getLastWeeks(uniqueEarnings, 4);
        break;
      case 'last3Months':
        filtered = getLastWeeks(uniqueEarnings, 12);
        break;
      case 'last6Months':
        filtered = getLastWeeks(uniqueEarnings, 24);
        break;
      case 'lastYear':
        filtered = getLastMonths(uniqueEarnings, 12);
        break;
      case 'currentFinancialYear':
        const currentYear = now.getFullYear();
        const financialYearStart = new Date(currentYear, 3, 1); // April 1st
        filtered = uniqueEarnings.filter(e => e.date >= financialYearStart);
        break;
      case 'previousFinancialYear':
        const previousYear = now.getFullYear() - 1;
        const previousFinancialYearStart = new Date(previousYear, 3, 1); // April 1st
        const previousFinancialYearEnd = new Date(previousYear + 1, 2, 31); // March 31st
        filtered = uniqueEarnings.filter(e => e.date >= previousFinancialYearStart && e.date <= previousFinancialYearEnd);
        break;
      case 'custom':
        const { start, end } = customRange;
        const customStart = new Date(start);
        const customEnd = new Date(end);
        filtered = uniqueEarnings.filter(e => e.date >= customStart && e.date <= customEnd);
        break;
      default:
        filtered = uniqueEarnings;
    }

    setFilteredEarnings(filtered);
  };

  const isSameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getDate() === d2.getDate();
  };

  const handleFilterChange = (event) => {
    if(event.target.value !== 'none'){
      setFilter(event.target.value);
      applyFilter(earnings, event.target.value);
    }
  };

  const handleCustomRangeChange = (field, value) => {
    setCustomRange(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleApplyCustomRange = () => {
    applyFilter(earnings, 'custom');
  };

  const getLastWeeks = (earnings, weeks) => {
    const now = new Date();
    const result = [];
    for (let i = 0; i < weeks; i++) {
      const weekStart = new Date(now);
      weekStart.setDate(now.getDate() - now.getDay() - (7 * i));
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      const weekEarnings = earnings.filter(e => e.date >= weekStart && e.date <= weekEnd);
      // result.push(...weekEarnings); 
      result.push({
        range: `${formatDate(weekStart)} - ${formatDate(weekEnd)}`,
        earnings: weekEarnings,
        totalPoints: weekEarnings.reduce((acc, curr) => acc + curr.earnings, 0)
      });
    }
    return result;
  };

  const getLastMonths = (earnings, months) => {
    const now = new Date();
    const result = [];
    for (let i = 0; i < months; i++) {
      const monthStart = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const monthEnd = new Date(now.getFullYear(), now.getMonth() - i + 1, 0);
      const monthEarnings = earnings.filter(e => e.date >= monthStart && e.date <= monthEnd);
      result.push({
        range: `${monthStart.toLocaleString('default', { month: 'long' })} ${monthStart.getFullYear()}`,
        earnings: monthEarnings,
        totalPoints: monthEarnings.reduce((acc, curr) => acc + curr.earnings, 0)
      });
    }
    return result;
  };



  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };
  // console.log(filteredEarnings)
  // Earnings ends here

const handleExport = () => {
    const data = [];
    
    // Add table headers
    data.push(["Time", "Result", "Previous", "Price", "Prediction"]);
    
    // Add table rows
    // eslint-disable-next-line
    Array.from(Array(15), (e, i) => {
      const row = [];
      row.push(i);
      row.push(superKeys.includes(supertime[i] + ' pm') ? 
        (superValues[superKeys.indexOf(supertime[i] + ' pm')] ? 'Correct' : 'Incorrect') : '--');
      row.push(superKeys.includes(supertime[i] + ' pm') ? 
        openPrice[superKeys.indexOf(supertime[i] + ' pm')] : '--');
      row.push(superKeys.includes(supertime[i] + ' pm') ? 
        Closeprice[superKeys.indexOf(supertime[i] + ' pm')] : '--');
      row.push(superKeys.includes(supertime[i] + ' pm') ? 
        (Updown[superKeys.indexOf(supertime[i] + ' pm')] === 1 ? 'UP' : 'DOWN') : 
        (currentDate.split('/')[0] > UniDate.getDate() ? 'UPCOMING' : 
        (currentDate.split('/')[0] < UniDate.getDate() ? 'MISSED' : 
        (UniDate.getHours() < 12 ? 'UPCOMING' : 
        (tradeData === supertime[i] ? 'AWAITING' : 
        (i <= supertime.indexOf(SetUni) ? 'MISSED' : 
        (UniDate.getHours() < 19 ? 'UPCOMING' : 'MISSED')))))));
      data.push(row);
    });
    
    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "report.xlsx");
  };

  const handleExport2 = () => {
    const data = [];
    
    // Add table headers
    data.push(["Range", "Daily Earnings"]);
    
    // Add table rows
    filteredEarnings.forEach((earning, index) => {
      const row = [];
      row.push(earning.range);
      row.push(earning.totalPoints === undefined ? earning.earnings : earning.totalPoints);
      data.push(row);
    });
    
    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Earnings");
    
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "earnings.xlsx");
  };

if(Switch){
  return (
    <div style={{backgroundColor:'#121212',height:window.screen.width<600?'160vh':'330vh',overflowY:'auto'}}>
      <div className="topbar-reports">
        <ArrowBackIcon onClick={()=> window.location="/"} className='reports-item-icon'/>
        <div className="rspace"></div>
        {/* <HelpOutlineIcon className='reports-help-item-icon'/> */}
      </div>
      <div className="report-switch">
        <div id='reports1' onClick={switchnow} className="insights-btn">
          Insights
          <div id='line-reports1' className="select-line"></div>
        </div>
        <div id='reports2' onClick={switchnow} className="earnings-btn">
          Earnings
          <div id='line-reports2' className="select-line" style={{display:'none'}}></div>
        </div>
      </div>
      <DatePicker className='date-picker' onSelect={handleDateSelect} selected={startDate} onChange={(date) => setStartDate(date)} />

      <button className='filter-apply-btn' onClick={handleExport}>Export to Excel</button>
      <table className="reports-table">
      <thead>
        <tr>
          <th>Time</th>
          <th>Result</th>
          <th>Previous</th>
          <th>Price</th>
          <th>Prediction</th>
        </tr>
      </thead>
      <tbody style={{color:'white'}}>
        {Array.from(Array(15),(e,i)=>{
          return <tr key={i}>
            <td>{i}</td>
            <td>{superKeys.includes(supertime[i]+ ' pm') ?  (superValues[superKeys.indexOf(supertime[i]+ ' pm')] ? ('Correct'):('Incorrect')):('--')}</td>
            <td>{superKeys.includes(supertime[i]+ ' pm') ? openPrice[superKeys.indexOf(supertime[i]+ ' pm')]:'--'}</td>
            <td>{superKeys.includes(supertime[i]+ ' pm') ? Closeprice[superKeys.indexOf(supertime[i]+ ' pm')]:'--'}</td>
            <td>{superKeys.includes(supertime[i]+ ' pm') ?  (Updown[superKeys.indexOf(supertime[i]+ ' pm')] === 1? ('UP'):('DOWN')):((currentDate.split('/')[0] > UniDate.getDate() ?'UPCOMING':(currentDate.split('/')[0] < UniDate.getDate() ?'MISSED':(UniDate.getHours() < 12?'UPCOMING':(tradeData === supertime[i]?'AWAITING':(i <= supertime.indexOf(SetUni)?'MISSED':(UniDate.getHours() < 19?'UPCOMING':'MISSED')))))))}</td>
          </tr>
        })}
      </tbody>
    </table>
      <div className="insights">

        {/* eslint-disable-next-line */}
        {Array.from(Array(15),(e,i)=>{
          return <div id={'IDot'+i} className="insights-dot" style={{top:(screenWidth < 600?10:20)*i + '%',marginTop:'20vh',left: i % 2 !== 0?(screenWidth < 600?35:39) +'vw':(screenWidth < 600?60:50) +'vw'}}></div>
          
        })}
        {Array.from(Array(14),(e,i)=>{
          return <div id={'ILine'+i} className="insights-line" style={{top:((screenWidth < 600?10:20))*i + '%',marginTop:(screenWidth < 600?18.5:(screenWidth > 1000 && screenWidth < 1400?18.5:16)) +'vh',transform:i % 2 !== 0?`rotate(${rotatenDeg})`:`rotate(${rotateDeg})`,left:(screenWidth < 600?50:45) +'vw',height:(screenWidth < 600?15:(screenWidth > 1000 && screenWidth < 1400?25:30))+'vh'}}></div>
          
        })}
        {Array.from(Array(15),(e,i)=>{
          return <div id={'ITxt'+i} className="txt-line" style={{top:((screenWidth < 600?10:20))*i + '%',marginTop:(screenWidth < 600?21:(screenWidth > 1000 && screenWidth < 1400?21:21)) +'vh',left: i % 2 !== 0?(screenWidth < 600?30:34) +'vw':(screenWidth < 600?64.5:51) +'vw',transform: i % 2 !== 0?'rotate(180deg)':'rotate(0deg)'}}></div>
          
        })}
        {Array.from(Array(15),(e,i)=>{
          return <span  id={'IDText'+i}  className="dot-text" style={{top:((screenWidth < 600?10:20))*i + '%',marginTop:(screenWidth < 600?20.5:(screenWidth > 1000 && screenWidth < 1400?20.5:20.2)) +'vh',left: i % 2 !== 0?(screenWidth < 600?42:41) +'vw':(screenWidth < 600?45:45) +'vw'}}>{supertime[i] + ' pm'}</span>
          
        })}
        {Array.from(Array(15),(e,i)=>{
          return <div  id={'IArr'+i}  className="insight-arr" style={{top:((screenWidth < 600?10:20))*i + '%',marginTop:(screenWidth < 600?17:(screenWidth > 1000 && screenWidth < 1400?15:15)) +'vh',left: i % 2 !== 0?(screenWidth < 600?6:20) +'vw':(screenWidth < 600?68.5:56) +'vw',width:screenWidth < 600?100:14 +'vw',height:screenWidth < 600?68:11 +'vh',backgroundColor: superKeys.includes(supertime[i]+ ' pm') ?  (superValues[superKeys.indexOf(supertime[i]+ ' pm')] ? ('#32ba7a7e'):('#e8684e7e')):('#3030307e'),borderColor:superKeys.includes(supertime[i]+ ' pm') ?  (superValues[superKeys.indexOf(supertime[i]+ ' pm')] ? ('#32ba7a'):('#e8684e')):('#303030')}}>
            <span className='ins-card-title' style={{fontSize:screenWidth < 600?'16px':'19px'}}>{superKeys.includes(supertime[i]+ ' pm') ?  (Updown[superKeys.indexOf(supertime[i]+ ' pm')] === 1? ('UP'):('DOWN')):((currentDate.split('/')[0] > UniDate.getDate() ?'UPCOMING':(currentDate.split('/')[0] < UniDate.getDate() ?'MISSED':(UniDate.getHours() < 12?'UPCOMING':(tradeData === supertime[i]?'AWAITING':(i <= supertime.indexOf(SetUni)?'MISSED':(UniDate.getHours() < 19?'UPCOMING':'MISSED')))))))}</span>
            <span className='ins-card-txt' style={{fontSize:screenWidth < 600?'12px':'14px'}}>Open : {superKeys.includes(supertime[i]+ ' pm') ? openPrice[superKeys.indexOf(supertime[i]+ ' pm')]:'--'}</span>
            <span className='ins-card-txt' style={{fontSize:screenWidth < 600?'12px':'14px'}}>Close : {superKeys.includes(supertime[i]+ ' pm') ? Closeprice[superKeys.indexOf(supertime[i]+ ' pm')]:'--'}</span>
            <span className='ins-card-txt' style={{fontSize:screenWidth < 600?'12px':'14px'}}>Result : {superKeys.includes(supertime[i]+ ' pm') ?  (superValues[superKeys.indexOf(supertime[i]+ ' pm')] ? ('Correct'):('Incorrect')):('--')}</span>
          </div>
          
        })}
      </div>
    </div>
  )
} else {
  return (
    <div style={{backgroundColor:'#121212',height:'100vh',overflowY:'auto'}}>
      <div className="topbar-reports">
        <ArrowBackIcon onClick={()=> window.location="/"} className='reports-item-icon'/>
        <div className="rspace"></div>
        {/* <HelpOutlineIcon className='reports-help-item-icon'/> */}
      </div>
      <div className="report-switch">
        <div id='reports1' onClick={switchnow} className="insights-btn">
          Insights
          <div id='line-reports1' className="select-line"></div>
        </div>
        <div id='reports2' onClick={switchnow} className="earnings-btn">
          Earnings
          <div id='line-reports2' className="select-line" style={{display:'none'}}></div>
        </div>
      </div>
      <div>
      <h2 style={{color:'white',marginLeft:'15px'}}>Earnings</h2>
      <div>
        <label style={{color:'white',marginLeft:'15px'}}>Duration : </label>
        <select value={filter} className='date-picker' onChange={handleFilterChange}>
          <option value="none">Select Duration</option>
          <option value="previousDay">Previous Day</option>
          <option value="lastWeek">Last Week</option>
          <option value="last30Days">Last 30 Days</option>
          <option value="last3Months">Last 3 Months</option>
          <option value="last6Months">Last 6 Months</option>
          {/* <option value="lastYear">Last Year</option> */}
          {/* <option value="currentFinancialYear">Current Financial Year</option> */}
          {/* <option value="previousFinancialYear">Previous Financial Year</option> */}
          {/* <option value="custom">Custom Date Range</option> */}
        </select>
      </div>
      {filter === 'custom' && (
        <div>
          <label style={{color:'white',marginLeft:'15px'}} >Start Date: </label>
          <input className='date-picker' type="date" value={customRange.start} onChange={(e) => handleCustomRangeChange('start', e.target.value)} />
          <label style={{color:'white',marginLeft:'15px'}} >End Date: </label>
          <input type="date" className='date-picker' value={customRange.end} onChange={(e) => handleCustomRangeChange('end', e.target.value)} />
          <button className='filter-apply-btn' onClick={handleApplyCustomRange}>Apply</button>
        </div>
      )}


      <button style={{display: filter === 'none' && 'none'}} className='filter-apply-btn' onClick={handleExport2}>Export to Excel</button>
      <table className="reports-table" style={{display: filter === 'none' && 'none'}}>
      <thead>
        <tr>
          <th>Range</th>
          <th>Daily Earnings</th>
        </tr>
      </thead>
      <tbody>
        {filteredEarnings.map((earning, index) => (
          <tr key={index}>
            <td>{earning.range}</td>
            <td>${earning.totalPoints=== undefined?earning.earnings: earning.totalPoints}</td>
          </tr>
        ))}
      </tbody>
       </table>
      <ul>
        {filteredEarnings.map((period, index) => (
          <li className='earnings-list' style={{display: filter === 'none' && 'none'}} key={index}>
            <div onClick={() => toggleExpand(index)}>
              <div className="e-list-in">
              {/* <img src="dollar.png" alt="" /> */}
              {period.range}
              </div>
              <div style={{height:'1px',backgroundColor:'black',width:'65%'}}></div>
              <div className="e-list-amt">
                <span>Amount Earned During The Period</span>
              ${period.totalPoints=== undefined?period.earnings: period.totalPoints}


              </div>
            </div>
            {expanded === index && (
              <ul>
                {period.earnings.map((day, idx) => (
                  <li className='earnings-list' key={idx}>{day.date.toDateString()}: {day.earnings}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      
      {filteredEarnings.length === 0 && 
      <div style={{width:'100%',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
        <img style={window.screen.width<600?{width:'30%',height:'30%'}:{width:'10%',height:'10%'}} src="nodatafound.png" alt="" />
      <span style={{color:'white'}}>No Data Found</span>
      </div>
      }
    </div>
    </div>
  )
}
  
}
